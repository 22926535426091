import router from '@router'
import store from '@state/store'
import Vue from 'vue'
import axios from 'axios'
import {
  Button,
  ConfigProgrammatic,
  Dialog,
  Field,
  Message,
  Table,
  Tabs,
  Tag,
  Upload,
  Icon,
  Snackbar,
  Toast,
  Tooltip,
  Modal,
} from 'buefy'
// Globally register all `_base`-prefixed components
import '@components/_globals'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faPaperclip,
  faCheck,
  faCircleCheck,
  faTriangleExclamation,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload,
  faRotate,
  faBookMedical,
  faMagnifyingGlass,
  faTrash,
  faCircleMinus,
  faCircleXmark,
  faForwardStep,
  faBars,
  faFileLines,
  faM,
  faCircleInfo,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './app.vue'
import AppLogger from './services/app-logger'
import { handleError } from './utils/error-text-handler'
import {
  storeXsrfToken,
  addXsrfTokenToRequest,
} from './utils/axios-interceptors'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

if (Vue.config.productionTip) {
  Vue.prototype.$http = axios
  Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
}

library.add(
  faPaperclip,
  faCheck,
  faCircleCheck,
  faTriangleExclamation,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faAngleUp,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload,
  faRotate,
  faBookMedical,
  faMagnifyingGlass,
  faTrash,
  faCircleMinus,
  faCircleXmark,
  faForwardStep,
  faBars,
  faFileLines,
  faM,
  faCircleInfo,
  faCircleExclamation
)
Vue.component('VueFontawesome', FontAwesomeIcon)
Vue.use(Button)
Vue.use(Field)
Vue.use(Message)
Vue.use(Tabs)
Vue.use(Table)
Vue.use(Tag)
Vue.use(Upload)
Vue.use(Icon)
Vue.use(Snackbar)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.use(Modal)

ConfigProgrammatic.setOptions({
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
})

Vue.config.errorHandler = (err, vm, info) => {
  AppLogger.logToServer({ err, vm, info })
}

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}

window.onerror = function (message, source, lineno, colno, error) {
  AppLogger.logToServer({ message, source, lineno, colno, error })
}

axios.interceptors.response.use(storeXsrfToken, handleError)

axios.interceptors.request.use(addXsrfTokenToRequest)
