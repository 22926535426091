import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { SubmitStatus } from '@utils/submit-status'

export const state = {
  attachmentFiles: [],
  fileUploadResponses: [],
  uploadStatus: SubmitStatus.NotSubmitted,
}

export const getters = {
  attachmentFiles: (s) => s.attachmentFiles,
  uploadStatus: (s) => s.uploadStatus,
}

export const mutations = {
  ADD_ATTACHMENT(s, newValue) {
    s.attachmentFiles = [...s.attachmentFiles, newValue]
  },
  UPDATE_ATTACHMENT(s, updatedAttachment) {
    const index = s.attachmentFiles.findIndex(
      (attachment) => attachment.id === updatedAttachment.id
    )
    if (index > -1) {
      s.attachmentFiles.splice(index, 1, updatedAttachment)
    }
  },
  REMOVE_ATTACHMENT(s, id) {
    const index = s.attachmentFiles.findIndex((a) => a.id === id)
    if (index > -1) {
      s.attachmentFiles.splice(index, 1)
    }
  },
  SET_UPLOAD_STATUS(s, status) {
    s.uploadStatus = status
  },
}

export const actions = {
  async addAttachment({ commit }, { sessionId, file }) {
    async function uploadAttachment() {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('id', file.id)

      try {
        const response = await axios.post(
          `/api/Dtr/Attachment?session=${sessionId}`,
          formData
        )

        file.uploadResponse = response
        file.externalAttachmentId = response.data?.attachmentIdentifier
        file.contentType = response.data?.contentType
        file.title = response.data?.title
        file.creation = response.data?.creation
        file.byteSize = response.data?.byteSize
      } catch (error) {
        file.hasUploadError = true
        file.uploadResponse = error.response
      }

      return file
    }

    commit('SET_UPLOAD_STATUS', SubmitStatus.Processing)

    file.id = uuidv4()
    file = await uploadAttachment()

    if (!file.hasUploadError) {
      commit('ADD_ATTACHMENT', file)
      commit('UPDATE_ATTACHMENT', file)
    }

    commit('SET_UPLOAD_STATUS', SubmitStatus.NotSubmitted)

    return file
  },
  removeAttachment({ commit }, { id }) {
    commit('REMOVE_ATTACHMENT', id)
  },
}
