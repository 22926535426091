import errorMessages from '@src/errorMessages'
import AppLogger from '@src/services/app-logger'
import { containsHTML } from '@src/utils/string-utils'

export function handleError(error) {
  const configUrl = error.config.url

  if (!configUrl.includes('/api/client/log') && isValidForCustomError(error)) {
    throw createCustomError()
  }

  if (!error.response) {
    // The request was made but no response was received
    AppLogger.logToServer(error)
  }

  throw error
}

export function isValidForCustomError(error) {
  return (
    containsHTML(error.message) ||
    containsHTML(error.response?.data) ||
    containsHTML(error.response?.data?.Detail)
  )
}

export function createCustomError() {
  const urlParams = new URLSearchParams(window.location.search)
  const sessionId = urlParams.get('sessionId')
  const error = new Error()
  error.response = {
    data: {
      Detail:
        errorMessages.Backend.UnexpectedError + ' Session ID: ' + sessionId,
    },
  }
  return error
}
