import sanitizeHtml from 'sanitize-html'

const allowedHtml = {
  nonTextTags: ['title', 'style'],
  allowedAttributes: {
    '*': ['style'],
  },
  allowedStyles: {
    '*': {
      // Match HEX, RGB, and name of the color
      color: [
        /^#(0x)?[0-9a-f]+$/i,
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
        /^[a-zA-Z]+$/,
      ],
    },
  },
  allowVulnerableTags: false,
}

export function isNullOrEmpty(str) {
  return str == null || str.length === 0
}

export function isNullOrWhitespace(str) {
  return isNullOrEmpty(str) || str.trim().length === 0
}

export function sanitizeHtmlWithAllowedHtml(htmlData) {
  return sanitizeHtml(htmlData, allowedHtml)
}

export function containsHTML(str) {
  const htmlRegex = /<[^>]*>/
  return htmlRegex.test(str)
}

export default {
  isNullOrEmpty,
  isNullOrWhitespace,
  sanitizeHtmlWithAllowedHtml,
  containsHTML,
}
