import store from '@state/store'
import { Xsrf } from './constants'

export function storeXsrfToken(response) {
  if (response?.config?.url?.toLowerCase().includes('/api/dtr/clientconfig')) {
    const xsrfToken = response.headers[Xsrf.ValidationHeader.toLowerCase()]
    if (xsrfToken) {
      store.dispatch('xsrfToken/storeXsrfToken', { xsrfToken: xsrfToken })
    }
  }
  return response
}

export function addXsrfTokenToRequest(request) {
  const xsrfToken = store.getters['xsrfToken/xsrfToken']
  if (xsrfToken) {
    request.headers[Xsrf.ValidationHeader] = xsrfToken
  }
  return request
}
