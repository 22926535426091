<script>
import Layout from '@layouts/main.vue'
import ContactDetail from '@views/contact-detail.vue'
import axios from 'axios'
import { postErrorToWindow } from '@src/postErrorToWindow'
import LoadingView from './_loading.vue'

export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  components: { Layout, LoadingView, ContactDetail },
  data() {
    return {
      offlineConfirmed: false,
    }
  },
  beforeCreate() {
    axios
      .head('/api/ping')
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        this.offlineConfirmed = true
        postErrorToWindow(
          this.$route.query.detail,
          this.$route.query.refererUrl,
          this.$route.query.notificationMode
        )
      })
  },
}
</script>

<template>
  <Layout v-if="offlineConfirmed">
    <div class="container is-max-desktop">
      <div style="top: 13px; left: 19px; width: 221.36px; height: 104.68px">
        <img alt="MCG DTR Service" src="@assets/images/logo.svg" />
      </div>
      <hr />
      <section>
        <img alt="Time out error" src="@assets/images/svgs/icon-warning.svg" />
        <p :class="$style.title"> Sorry, the connection has timed out. </p>
      </section>
      <div :class="$style.subTitle">
        <section>
          <p> ERROR CODE: {{ errorCode }} </p>
          <br />
          <span>
            The server at {{ server }} is taking too long to respond.The site
            could be temporarily unavailable or too busy. Please try one of the
            following steps to get you back on track.
            <br />
          </span>
          <br />
        </section>
        <ContactDetail></ContactDetail>
      </div>
    </div>
  </Layout>
  <LoadingView v-else />
</template>

<style lang="scss" module>
.title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  color: #394a5b;
}
.subTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #394a5b;
}
</style>
