'use strict'

const MedicationAdministration = require('./MedicationAdministration')
const randomize = ({ DateTime }) =>
  DateTime || `${Date.now()}${Math.floor(Math.random() * 1000000)}`
const defaultEncounterStart = () =>
  new Date(new Date() - 3600000 * 24).toISOString()
const getCodeSystemUri = (codeSystemOid, oidMapping) =>
  oidMapping[codeSystemOid] || codeSystemOid
const extractObservations = (obsArray = []) =>
  obsArray.reduce(
    (accumulator, { Observations = [] }) => [...accumulator, ...Observations],
    []
  )
const sourceMapKeys = {
  encounterCodes: 'sEncounterDiagnosesMap',
  problemCodes: 'sConditionsActiveMap',
  procedures: 'sProceduresMap',
  labData: 'sLabDataMap',
  vitalSigns: 'sVitalSignsMap',
  serviceRequests: 'sServiceRequestsMap',
}

const extractClinicalInput = (
  observationType,
  oidMapping,
  observationsArray = []
) => {
  if (!observationsArray || !Array.isArray(observationsArray)) return {}
  if (observationType === 'encounterCodes')
    observationsArray = observationsArray.filter(
      (item) => item.type === 'encounter-diagnosis'
    )
  return observationsArray.reduce(
    (
      observations,
      {
        fhirResourceMeta: { id } = {},
        Code,
        CodeSystem,
        DateTime,
        StartDate,
        AssertedDate,
        Name,
        Status,
        Interpretation,
        Value,
        Units,
        ReferenceRange,
        clinicalInputKey,
        DisplayValue,
        ValueType,
        hidden,
      }
    ) => {
      const codeSystemUri = getCodeSystemUri(CodeSystem, oidMapping)
      const observation = {
        Code,
        CodeSystem: codeSystemUri,
        Name,
        highlightMatch: false,
        ...(AssertedDate && { AssertedDate }),
      }

      if (
        observationType === 'problemCodes' ||
        observationType === 'procedures' ||
        observationType === 'encounterCodes' ||
        observationType === 'serviceRequests'
      ) {
        observation.DateTime = StartDate
      } else {
        Object.assign(observation, {
          DateTime,
          Value,
          Units,
          Status,
          DisplayValue,
          ValueType,
          hidden,
        })

        if (ReferenceRange) observation.ReferenceRange = ReferenceRange
        if (Interpretation) observation.Interpretation = Interpretation
      }

      observation.sourceMapKey = sourceMapKeys[observationType]
      observation.codingKey = `${observation.Code}${observation.CodeSystem}`
      observation.clinicalInputKey =
        clinicalInputKey ||
        `${Code}${codeSystemUri}${randomize(observation)}_${observationType}`
      observation.clinicalInputId = id || observation.clinicalInputKey
      observations[observation.clinicalInputKey] = observation

      return observations
    },
    {}
  )
}

module.exports = (modelParameters) => {
  const {
    clinicalInput,
    guidelineRoot,
    contentEdition = '',
    remedyBuild = '',
    reasoningBuild,
    codifiedRelease = '',
    oidMap: oidMapping = {},
    requestLogger,
    calculatorCache,
    offset,
    coverageMode,
  } = modelParameters
  const {
    MedicationRequest: medicationRequest = [],
    MedicationStatement: medicationStatement = [],
    MedicationAdministration: medicationAdministration = [],
    CurrentMedication: currentMedication = [],
    diagnosticReports = [],
    clinicalNotes = [],
    RawFhirData: rawFhirData = [],
    fhirBundle,
    Encounters: encounters,
    evaluationTime = Date.now(),
    responseType: mode,
    Patient: fhirPatientData,
    Patient: { gender: administrativeGender = null } = {},
    nlpResults = [],
    ServiceRequests: serviceRequests = [],
  } = clinicalInput

  const encounterStart =
    clinicalInput.Encounters && clinicalInput.Encounters.length
      ? clinicalInput.Encounters[0].DateTime
      : defaultEncounterStart()
  const encounterCodes = extractClinicalInput(
    'encounterCodes',
    oidMapping,
    clinicalInput.Problems
  )
  const problemCodes = extractClinicalInput(
    'problemCodes',
    oidMapping,
    clinicalInput.Problems
  )
  const procedureCodes = extractClinicalInput(
    'procedures',
    oidMapping,
    clinicalInput.Procedures
  )
  const serviceRequestCodes = extractClinicalInput(
    'serviceRequests',
    oidMapping,
    serviceRequests
  )
  const labdata = extractClinicalInput(
    'labData',
    oidMapping,
    extractObservations(clinicalInput.LabResults)
  )
  const vitalSigns = extractClinicalInput(
    'vitalSigns',
    oidMapping,
    extractObservations(clinicalInput.VitalSigns)
  )
  const { medicationAdministrations } = new MedicationAdministration(
    medicationAdministration
  )
  const observationOther = extractObservations(clinicalInput.observationOther)
  const patient =
    clinicalInput.Header && clinicalInput.Header.Patient
      ? clinicalInput.Header.Patient
      : {}
  const patientIdentifiers =
    patient.Identifiers && patient.Identifiers.length ? patient.Identifiers : []
  const patientId = patientIdentifiers[0] || ''
  const dateOfBirth = patient.Demographics ? patient.Demographics.DOB : ''
  const contentTag = `not evaluated`

  return {
    appInfo: {
      remedyBuild,
      reasoningBuild,
      contentEdition,
      codifiedRelease,
    },
    encounterStart,
    evaluationTime,
    clinicalInput: {
      encounters,
      encounterCodes,
      problemCodes,
      procedureCodes,
      serviceRequestCodes,
      labdata,
      vitalSigns,
      diagnosticReports,
      clinicalNotes,
      medicationAdministrations,
      observationOther,
      rawFhirData,
      ...(fhirBundle && { fhirBundle }),
      fhirPatientData,
      nlpResults,
      rawMedicationData: {
        medicationRequest,
        medicationStatement,
        medicationAdministration,
        currentMedication,
      },
      patientMeta: {
        patientId,
        patientIdentifiers,
        dateOfBirth,
        administrativeGender,
      },
    },
    outline: {
      definition: [],
      outlineNote: {
        note: '',
        active: '',
        noteType: 'Admission',
      },
      search: clinicalInput.search || [],
    },
    contentTree: guidelineRoot,
    user: '',
    createdAt: new Date().toISOString(),
    clinicalSourceInput: {
      patientMeta: {
        dateOfBirth,
        administrativeGender,
        fNow: evaluationTime,
        fEncounterStart: Date.parse(encounterStart),
      },
      conceptSourceInputMap: {
        sEncounterDiagnosesMap: {
          name: 'encounter',
          data: encounterCodes,
          populatedConcepts: {},
        },
        sConditionsActiveMap: {
          name: 'problem',
          data: problemCodes,
          populatedConcepts: {},
        },
        sMedicationAdministrationsMap: {
          name: 'medicationAdministrations',
          data: medicationAdministrations,
          populatedConcepts: {},
        },
        sLabDataMap: {
          name: 'lab',
          data: labdata,
          populatedConcepts: {},
        },
        sObservationsOtherMap: {
          name: 'lab',
          data: labdata,
          populatedConcepts: {},
        },
        sVitalSignsMap: {
          name: 'vitalsign',
          data: vitalSigns,
          populatedConcepts: {},
        },
        sProceduresMap: {
          name: 'procedure',
          data: procedureCodes,
          populatedConcepts: {},
        },
        sServiceRequestsMap: {
          name: 'serviceRequest',
          data: serviceRequestCodes,
          populatedConcepts: {},
        },
        sDefaultMap: {
          data: [],
          populatedConcepts: {},
        },
      },
      calculators: {},
      timeRangeCache: new Map(),
      calculatorCache,
      conceptValueCache: new Map(),
      patientDataMap: new Map([
        ['sEncounterDiagnoses', Object.values(encounterCodes)],
        ['sConditionsActive', Object.values(problemCodes)],
        [
          'sMedicationAdministrations',
          Object.values(medicationAdministrations),
        ],
        ['sLabData', Object.values(labdata)],
        ['sObservationsOther', Object.values(labdata)],
        ['sVitalSigns', Object.values(vitalSigns)],
        ['sProcedures', Object.values(procedureCodes)],
        ['sServiceRequests', Object.values(serviceRequestCodes)],
        ['sDefault', []],
      ]),
      matches: [],
      matchedNodes: {},
      mode,
      offset,
      requestLogger,
      valueSets: {},
      contentTag,
      injectedDefMatchedClinicalKeys: {},
      ...(coverageMode && { coverageMode }),
    },
  }
}
