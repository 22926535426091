import axios from 'axios'
const binaryCache = new Map()

export const state = {
  clinicalNotesBinaryError: [],
  sortOrder: { field: 'date', order: 'desc' },
}

export const getters = {
  clinicalNotesBinaryError: (s) => s.clinicalNotesBinaryError,
  sortOrder: (s) => s.sortOrder,
}

export const mutations = {
  CLINICAL_NOTES_BINARY_ERROR(s, newValue) {
    s.clinicalNotesBinaryError[newValue.index] = newValue
  },
  SAVE_SORT_ORDER(s, newValue) {
    s.sortOrder = newValue
  },
}

const handleResponse = (commit, contentPath, response, index) => {
  binaryCache.set(contentPath, response)
  const clinicalNotesBinary = {
    data: response.data?.data,
    contentType: response.data.contentType,
    index: index,
  }

  commit('CLINICAL_NOTES_BINARY_ERROR', { error: null, index: index })
  return clinicalNotesBinary
}

export const actions = {
  retrieveClinicalNotesBinary({ commit }, { sessionId, contentPath, index }) {
    const response = binaryCache.get(contentPath)

    if (response) {
      return handleResponse(commit, contentPath, response, index)
    }

    return axios
      .get(
        `/api/Dtr/FhirContent?session=${sessionId}&contentPath=${contentPath}`
      )
      .then((response) => {
        return handleResponse(commit, contentPath, response, index)
      })
      .catch((error) => {
        commit('CLINICAL_NOTES_BINARY_ERROR', { error: error, index: index })
        return { error: error, index: index }
      })
  },
  saveSortOrder({ commit }, newValue) {
    commit('SAVE_SORT_ORDER', newValue)
  },
}
