<script>
import Header from '@components/header.vue'
import Footer from '@components/footer.vue'
import TimeoutWarning from '@components/timeout-warning.vue'

export default {
  components: { Header, Footer, TimeoutWarning },
  data() {
    return {
      time: '',
      timeoutActive: false,
    }
  },
  methods: {
    displayTimer(displayTime) {
      if (process.env.VUE_APP_DISPLAY_TIME) {
        this.time = displayTime
      }
    },
    timeoutWarningActive() {
      this.$emit('closeHelp')
      this.timeoutActive = true
    },
  },
}
</script>

<template>
  <div class="is-max-widescreen">
    <TimeoutWarning
      :message="'Your session is about to expire.'"
      :cancel-text="'End Session'"
      :confirm-text="'Continue Session'"
      @displayTimer="displayTimer"
      @timeoutWarning="timeoutWarningActive"
    />
    <Header />
    <slot />
    <Footer :timer="time" :timeout-active="timeoutActive" />
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.fixed-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  height: calc(100vh - 4rem);
  margin: 0.25rem;
}
</style>
