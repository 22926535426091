import axios from 'axios'

class AppLogger {
  static async logToServer(error) {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const sessionId = urlParams.get('sessionId')

      const response = await this.sendLogToServer(error, sessionId)
      if (response !== undefined && response?.status !== 200) {
        console.error(
          'Failed to log error to server, status: ',
          response?.status
        )
        console.error('Response: ', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  static sendLogToServer(error, sessionId) {
    return axios.post(`/api/client/log?sessionId=${sessionId}&appName=dtr-ui`, {
      error: error.message || error.err.message || error.error.message,
      stackTrace: error.stack || error.err.stack || error.error.stack,
    })
  }
}

export default AppLogger
