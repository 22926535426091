<script>
import Layout from '@layouts/main.vue'
import ContactDetail from '@views/contact-detail.vue'
export default {
  page: {
    title: 'Error',
    meta: [{ name: 'description', content: 'Error' }],
  },
  components: { Layout, ContactDetail },
  computed: {
    errorCode() {
      return this.$route.query?.code
    },
    errorDetails() {
      if (!this.$route.query.detail) {
        return ''
      } else {
        return this.$route.query?.detail?.split('\\n')
      }
    },
    sessionId() {
      return this.$route.query?.sessionId
    },
    handledException() {
      return this.$route.query?.handled === 'true'
    },
  },
}
</script>

<template>
  <Layout>
    <div class="container">
      <div style="top: 13px; left: 19px; width: 221.36px; height: 104.68px">
        <img alt="MCG DTR Service" src="@assets/images/logo.svg" />
      </div>
      <hr />
      <section>
        <img alt="generic error" src="@assets/images/svgs/icon-warning.svg" />
        <p v-if="!handledException" :class="$style.title" data-cy="error-header"
          >Sorry, we are having a problem with our system.</p
        >
      </section>
      <div :class="$style.subTitle">
        <section>
          <div data-cy="error-code">
            <p v-if="errorCode">ERROR CODE: {{ errorCode }}</p>
          </div>
          <div v-for="line in errorDetails" :key="line" data-cy="error-message">
            {{ line }}
          </div>
        </section>
        <div v-if="!handledException" data-cy="error-contact">
          <ContactDetail></ContactDetail>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" module>
.title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  color: #394a5b;
}
.subTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #394a5b;
  word-wrap: break-word;
}
</style>
