import { render, staticRenderFns } from "./header.vue?vue&type=template&id=3cf3eabd&scoped=true&functional=true&"
var script = {}
import style0 from "./header.vue?vue&type=style&index=0&id=3cf3eabd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "3cf3eabd",
  null
  
)

export default component.exports