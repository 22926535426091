import { DateTime } from 'luxon'

export const formatDateMixin = {
  methods: {
    isValidIsoDate(dateTimeString) {
      return DateTime.fromISO(dateTimeString).isValid
    },
    convertIsoToDate(dateTimeString) {
      return DateTime.fromISO(dateTimeString).toLocaleString(
        DateTime.DATE_SHORT
      )
    },
    convertIsoToDateTime(dateTimeString) {
      return DateTime.fromISO(dateTimeString).toLocaleString(
        DateTime.DATETIME_SHORT
      )
    },
  },
}

export default { formatDateMixin }
