'use strict'

const { buildScdm } = require('./scdmBuilder')
const getCategoryType = (category, type) => {
  if (!Array.isArray(category))
    return category.coding.some(({ code }) => code === type)

  return category.some((categoryItem) =>
    categoryItem.coding.some(({ code }) => code === type)
  )
}

const extractFhirResources = (bundle, targetObject, logger) =>
  bundle.entry.forEach(({ resource }) => {
    const { resourceType } = resource

    switch (resourceType) {
      case 'Patient':
        targetObject.Patient.push(resource)

        break
      case 'Encounter':
        targetObject.Encounter.push(resource)

        break
      case 'Observation':
        if (getCategoryType(resource.category, 'vital-signs')) {
          targetObject.VitalSign.push(resource)
        } else if (getCategoryType(resource.category, 'laboratory')) {
          targetObject.Laboratory.push(resource)
        } else {
          targetObject.observationOther.push(resource)
        }

        break
      case 'Condition':
        if (
          getCategoryType(resource.category, 'diagnosis') ||
          getCategoryType(resource.category, 'encounter-diagnosis')
        ) {
          targetObject.EncounterDiagnosis.push(resource)
        } else {
          targetObject.Problem.push(resource)
        }

        break
      case 'Procedure':
        targetObject.Procedure.push(resource)

        break
      case 'ServiceRequest':
        targetObject.ServiceRequest.push(resource)

        break
      case 'MedicationStatement':
        targetObject.MedicationStatement.push(resource)

        break
      case 'MedicationAdministration':
        targetObject.MedicationAdministration.push(resource)

        break
      case 'MedicationRequest':
        targetObject.MedicationRequest.push(resource)

        break
      case 'DiagnosticReport':
        targetObject.DiagnosticReport.push(resource)

        break
      case 'DocumentReference':
        targetObject.ClinicalNote.push(resource)

        break
      default:
        logger.info('FHIR resource not added to Synapse model', {
          fhirId: resource.id,
          fhirResourceType: resourceType,
        })
    }
  })

function convertBundleToScdm(body, requestLogger) {
  requestLogger.info('convertBundleToScdm')

  const fhir = {
    Patient: [],
    Encounter: [],
    Procedure: [],
    ServiceRequest: [],
    Problem: [],
    EncounterDiagnosis: [],
    VitalSign: [],
    Laboratory: [],
    ClinicalNote: [],
    DiagnosticReport: [],
    MedicationRequest: [],
    MedicationStatement: [],
    MedicationAdministration: [],
    observationOther: [],
  }

  try {
    extractFhirResources(body.bundle, fhir, requestLogger)
  } catch (error) {
    requestLogger.error('convertBundleToScdm', {
      message: error.message,
      stack: error.stack,
      status: 400,
    })
  }

  fhir.Patient = fhir.Patient[0]
  fhir.Encounter = fhir.Encounter[0]

  return setRequestBody(body, fhir, requestLogger)
}

function setRequestBody(body, fhir, requestLogger) {
  requestLogger.info('setRequestBody')

  const { nlpResults = [], CurrentMedication = [] } = body
  let product
  let scdm

  try {
    product = body.meta.product || 'No Product'

    if (!product)
      throw Error(`Unable to determine product for ${body.meta.hsim}`)
  } catch (error) {
    requestLogger.error('setRequestBody', {
      message: error.message,
      stack: error.stack,
      status: error.status,
    })
  }

  try {
    scdm = buildScdm(fhir, body.meta, requestLogger)
  } catch (error) {
    requestLogger.error('setRequestBody', {
      message: error.message,
      stack: error.stack,
      status: 400,
    })
  }

  body = {
    config: body.meta,
    data: {
      product,
      nlpResults,
      CurrentMedication,
      fhirBundle: body.bundle,
      ...scdm,
    },
  }

  return { body, fhir }
}

module.exports.setRequestBody = setRequestBody
module.exports.convertBundleToScdm = convertBundleToScdm
module.exports.getCategoryType = getCategoryType
module.exports.extractFhirResources = extractFhirResources
