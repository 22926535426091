const codeSystemFilter = 'urn:oid:'
const csnOid = '3.698084.8'
const codeSystemMapping = require('./codeSystemMapping.json')

const getAssertedDateTime = (condition) => {
  const { dateRecorded, assertedDate, recordedDate } = condition
  return dateRecorded || assertedDate || recordedDate
}

const getOnsetDateTime = (condition) => {
  const { onsetDateTime, onsetPeriod: { start } = {} } = condition
  return start || onsetDateTime
}

const scdmItemDateTime = (resourceType, dateTimeItem) => {
  switch (resourceType) {
    case 'Procedure':
      return dateTimeItem.performedDateTime
    case 'ServiceRequest':
      return dateTimeItem.authoredOn
    case 'Condition':
      return getOnsetDateTime(dateTimeItem) || getAssertedDateTime(dateTimeItem)
    default:
      return ''
  }
}

const handleObservationComponent = (observations, observation, components) => {
  const { effectiveDateTime, id } = observation

  components.forEach((component, index) => {
    component.effectiveDateTime = effectiveDateTime
    component.id = `${id}_comp${index}`
    observations.push(component)
  })
}

const itemDisplayValue = (item = {}, length = false) => {
  const { text = null, coding: [{ display = '' } = {}] = [] } = item
  const fullText = text || display
  const formattedDisplayValue =
    length && fullText.length > length
      ? `${fullText.slice(0, length)}...`
      : fullText
  return formattedDisplayValue
}

const codeSystemName = (codeSystem) =>
  codeSystemMapping[codeSystem] && codeSystemMapping[codeSystem].CodeSystemName
    ? codeSystemMapping[codeSystem].CodeSystemName
    : ''

const logMappingSuccess = (
  logger,
  resourceType,
  mappedCodeSystem,
  filteredCodeSystem
) => {
  const message = `${resourceType}: Successfully mapped ${filteredCodeSystem} to ${mappedCodeSystem}`
  logger.info('fhirLog', {
    logType: 'codeSystemTranslationSuccessLog',
    message,
  })
}

const logMappingFailure = (logger, resourceType, codings, unmappedUris) => {
  if (codings.length && codings.length === unmappedUris.length) {
    const message = `${resourceType}: Mapping Failure for Code System: ${JSON.stringify(
      codings
    )}`
    logger.error('fhirLog', {
      logType: 'codeSystemTranslationFailLog',
      message,
    })
  }
}

const codeSystemUri = (coding, logger, unmappedUris, resourceType) => {
  const filteredCodeSystem = coding.system.replace(codeSystemFilter, '').trim()
  let mappedUri = filteredCodeSystem

  if (codeSystemMapping[filteredCodeSystem]) {
    const { Uri: mappedCodeSystem = filteredCodeSystem } =
      codeSystemMapping[filteredCodeSystem]

    if (mappedCodeSystem !== filteredCodeSystem) {
      logMappingSuccess(
        logger,
        resourceType,
        mappedCodeSystem,
        filteredCodeSystem
      )
      mappedUri = mappedCodeSystem
    }
  } else if (unmappedUris) {
    unmappedUris.push(filteredCodeSystem)
  }

  return mappedUri
}

const codeObjectsForCodeSystem = (
  targetCodeSystem,
  logger,
  { coding },
  resourceType
) => {
  const unmappedUris = []
  coding = coding || []
  const preferredCodes = coding.reduce((found, codeElement) => {
    codeElement.system = codeSystemUri(
      codeElement,
      logger,
      unmappedUris,
      resourceType
    )
    if (codeElement.system === targetCodeSystem) found.push(codeElement)
    return found
  }, [])
  logMappingFailure(logger, resourceType, coding, unmappedUris)

  return preferredCodes.length ? preferredCodes : coding
}

const getValueKey = (observation) =>
  Object.keys(observation).find((key) => key.startsWith('value'))

const formatValueType = (valueKey) => {
  if (!valueKey) return 'none'
  const type = valueKey.slice(5)
  return `${type.charAt(0).toLowerCase()}${type.slice(1)}`
}

const observationValue = (observation, type, logger) => {
  const {
    valueQuantity,
    valueCodeableConcept,
    valueString,
    valueBoolean,
    valueInteger,
    id,
  } = observation

  const preferredCodes = codeObjectsForCodeSystem(
    'http://loinc.org',
    logger,
    observation.code,
    type
  )
  const scdmResults = []
  const fhirResourceMeta = {
    id,
    text: observation.code.text,
  }

  preferredCodes.forEach(
    ({ code = '', system: codeSystem = '', display = '' }, index) => {
      fhirResourceMeta.text = fhirResourceMeta.text || display
      const valueKey = getValueKey(observation)
      const scdmRes = {
        Code: code,
        CodeSystem: codeSystem,
        CodeSystemName: codeSystemName(codeSystem),
        DisplayValue: '',
        ValueType: formatValueType(valueKey),
        Value: observation[valueKey],
        Name: observation.code.text || display,
        DateTime: observation.effectiveDateTime,
        Status: observation.status,
        Interpretation: itemDisplayValue(observation.interpretation),
        ReferenceRange: observation.referenceRange
          ? observation.referenceRange
          : [{ low: null, high: null, text: '' }],
        fhirResourceMeta,
      }

      if (valueQuantity) {
        scdmRes.Value = valueQuantity.value
        scdmRes.Units = valueQuantity.unit
        scdmRes.DisplayValue = valueQuantity.value
      } else if (valueCodeableConcept) {
        const maxLength = 200
        scdmRes.DisplayValue = itemDisplayValue(valueCodeableConcept, maxLength)
      } else if (valueString) {
        scdmRes.DisplayValue = valueString
      } else if (valueBoolean) {
        scdmRes.DisplayValue = valueBoolean.toString()
      } else if (valueInteger) {
        scdmRes.DisplayValue = valueInteger.toString()
      } else {
        scdmRes.hidden = true
      }

      scdmRes.clinicalInputKey = `${id}_${index}_${type}`
      scdmResults.push(scdmRes)
    }
  )
  return scdmResults
}

const logUnmappedResource = (logger, resource, type, context, rawFhir = []) => {
  const message =
    `${type} FHIR Mapping Failure: ${resource.resourceType} / ` +
    `${resource.id} not added to SCDM: ${context}`
  logger.info('fhirLog', { logType: 'mappingFailure', message })
  rawFhir.push({ message, resource })
}

const extractCSN = ({ identifier: identifiers = [] }) => {
  const { value = '' } =
    identifiers.find(({ system } = {}) => system.includes(csnOid)) || {}
  return value
}

const scdmResources = (
  resourceCollection,
  logger,
  type,
  rawFhir,
  encounterDate = undefined
) => {
  const itemArray = []
  resourceCollection.forEach((resource) => {
    const { resourceType, status, id } = resource

    if (resource.code && resource.code.coding) {
      const fhirResourceMeta = {
        id,
        text: resource.code.text,
      }

      const unmappedUris = []

      resource.code.coding.forEach((codeItem, index) => {
        fhirResourceMeta.text = fhirResourceMeta.text || codeItem.display
        const codeSystem = codeItem.system.replace(codeSystemFilter, '').trim()
        const scdmRes = {
          StartDate: encounterDate || scdmItemDateTime(resourceType, resource),
          EndDate: '',
          AssertedDate: getAssertedDateTime(resource),
          Code: codeItem.code,
          CodeSystem: codeSystemUri(codeItem, logger, unmappedUris, type),
          CodeSystemName: codeSystemName(codeSystem),
          Name: resource.code.text || codeItem.display,
          type,
          fhirResourceMeta,
        }

        if (resourceType === 'Procedure') {
          scdmRes.status = status
          scdmRes.performedDateTime = resource.performedDateTime
        }

        scdmRes.clinicalInputKey = `${id}_${index}_${type}`
        itemArray.push(scdmRes)
      })

      logMappingFailure(logger, type, resource.code.coding, unmappedUris)
    } else {
      logUnmappedResource(
        logger,
        resource,
        type,
        'Missing coding information.',
        rawFhir
      )
    }
  })
  return itemArray
}

const scdmObservations = (observations, logger, type, rawFhir) => {
  let processedObservations = []
  for (let i = 0; i < observations.length; i += 1) {
    const observation = observations[i]
    if (observation.component) {
      handleObservationComponent(
        observations,
        observation,
        observation.component
      )
    } else if (observation.code && observation.code.coding) {
      processedObservations = [
        ...processedObservations,
        ...observationValue(observation, type, logger),
      ]
    } else {
      logUnmappedResource(
        logger,
        observation,
        type,
        'No value or subcomponent available.',
        rawFhir
      )
    }
  }
  return processedObservations.sort(
    (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
  )
}

const scdmEncounter = (encounterData, logger) => {
  const encounters = []
  encounterData.forEach((encounter) => {
    if (encounter.period && encounter.period.start) {
      const encounterDate = encounter.period.start
      const thisPatientEncounter = {
        ID: encounter.id || '',
        DateTime: encounterDate,
        CSN: extractCSN(encounter),
      }
      encounters.push(thisPatientEncounter)
    } else {
      logger.error(`Encounter missing start period`)
    }
  })

  if (encounters.length > 0) {
    return encounters.sort(
      (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
    )
  }
  const message = 'Patient encounter required'
  logger.error(message)
  throw new Error(message)
}

const parseIdentifier = (identifier) => {
  let SourceSystem = ''
  let IDType = ''
  let CodeSystem = ''
  if (
    identifier.type &&
    identifier.type.coding &&
    identifier.type.coding.length
  ) {
    IDType = identifier.type.coding[0].code
    CodeSystem = identifier.type.coding[0].system
    SourceSystem = identifier.system
  } else {
    IDType = identifier.system
  }

  let Type = ''
  if (identifier.type && identifier.type.text) {
    Type = identifier.type.text
  } else if (identifier.type && identifier.type.coding) {
    Type = identifier.type.coding.display
  }

  let ID = ''
  if (identifier.value) {
    ID = identifier.value
  } else if (identifier.extension && identifier.extension[0].valueString) {
    ID = identifier.extension[0].valueString
    CodeSystem = identifier.extension[0].url
  }

  return {
    ID,
    IDType,
    CodeSystem,
    Type,
    SourceSystem,
  }
}

const scdmPatient = (patient) => {
  if (!patient) throw new Error('Patient is required')
  const Identifiers = [{ ID: patient.id, IDType: null, Type: 'FHIR Resource' }]
  if (patient.identifier) {
    patient.identifier.forEach((identifier) => {
      Identifiers.push(parseIdentifier(identifier))
    })
  }
  const familyName = patient.name ? patient.name[0].family[0] : ''
  const givenName = patient.name ? patient.name[0].given[0] : ''
  return {
    Identifiers,
    Demographics: {
      DOB: patient.birthDate,
      Sex: patient.gender,
      LastName: familyName,
      FirstName: givenName,
    },
  }
}

const buildScdm = (fhirData = {}, fhirMeta = {}, logger) => {
  const {
    Patient: fhirPatient,
    Encounter: fhirEncounter,
    Procedure: fhirProcedures = [],
    Problem: fhirProblems = [],
    EncounterDiagnosis: fhirEncounterDiagnoses = [],
    VitalSign: fhirVitalSigns = [],
    Laboratory: fhirLaboratory = [],
    ClinicalNote: clinicalNotes = [],
    DiagnosticReport: diagnosticReports = [],
    ServiceRequest: serviceRequests = [],
    ...OtherFhir
  } = fhirData
  const {
    hsim,
    user,
    mode,
    glos,
    search,
    guidelineTitle,
    guidelineCode,
    workFlowStartTime,
    evaluationTime,
  } = fhirMeta

  const RawFhirData = [fhirData]

  const Patient = scdmPatient(fhirPatient)
  const Encounters = scdmEncounter(
    [...(fhirEncounter ? [fhirEncounter] : [])],
    logger
  )
  const scdm = {
    Header: { Patient },
    Meta: { UserId: user },
    Patient: fhirPatient,
    Encounters,
    Problems: [
      ...scdmResources(fhirProblems, logger, 'Condition', RawFhirData),
      ...scdmResources(
        fhirEncounterDiagnoses,
        logger,
        'encounter-diagnosis',
        RawFhirData,
        Encounters[0].DateTime
      ),
    ],
    Procedures: scdmResources(fhirProcedures, logger, 'Procedure'),
    ServiceRequests: scdmResources(serviceRequests, logger, 'serviceRequests'),
    VitalSigns: [
      {
        Observations: scdmObservations(
          fhirVitalSigns,
          logger,
          'vitalSigns',
          RawFhirData
        ),
      },
    ],
    LabResults: [
      {
        Observations: scdmObservations(
          fhirLaboratory,
          logger,
          'labResults',
          RawFhirData
        ),
      },
    ],
    diagnosticReports,
    clinicalNotes,
    ...OtherFhir,
    RawFhirData,
    hsim,
    ...(search && { search }),
    ...(glos && { glos }),
    ...(guidelineCode && { guidelineCode }),
    ...(guidelineTitle && { guidelineTitle }),
    ...(workFlowStartTime && { workFlowStartTime }),
    ...(evaluationTime && { evaluationTime }),
    responseType: mode,
  }
  return scdm
}

module.exports = {
  scdmResources,
  scdmObservations,
  scdmEncounter,
  scdmPatient,
  codeSystemUri,
  logMappingSuccess,
  logMappingFailure,
  itemDisplayValue,
  buildScdm,
}
