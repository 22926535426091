'use strict'

const buildSynapseModel = require('./buildSynapseModel.js')
const oidMap = require('./oidMappings.json')

class GuidanceManagerModule {
  prepareForEvaluation({
    guideline,
    clinicalInput = {},
    contentEdition = '',
    remedyBuild = '',
    requestLogger,
    offset,
    coverageMode,
  }) {
    const calculatorCache = new Map()
    const modelParameters = {
      clinicalInput,
      guidelineRoot: guideline,
      contentEdition,
      remedyBuild,
      reasoningBuild: 'Not used',
      codifiedRelease: 'Not evaluated',
      calculatorCache,
      oidMap,
      requestLogger,
      offset,
      coverageMode,
    }
    const synapseModel = buildSynapseModel(modelParameters)
    requestLogger.info(`Synapse model built`)

    return synapseModel
  }

  evaluateClinicalInput({
    clinicalInput,
    guideline,
    requestLogger,
    contentEdition = '',
    remedyBuild = '',
    offset,
    mode,
    coverageMode,
  }) {
    const synapseModel = this.prepareForEvaluation({
      clinicalInput,
      guideline,
      contentEdition,
      remedyBuild,
      requestLogger,
      mode,
      offset,
      coverageMode,
    })

    try {
      requestLogger.info(`Guideline evaluation skipped`)

      this.postEvaluationTransform(synapseModel)
    } catch (err) {
      requestLogger.warn(
        `Guideline evaluation failed for guideline:${clinicalInput.hsim}`,
        err
      )
    }

    return synapseModel
  }

  replaceDotsWithUnderscores(clinicalInputs) {
    const categoryKeys = [
      'labdata',
      'problemCodes',
      'procedureCodes',
      'vitalSigns',
      'serviceRequestCodes',
    ]

    categoryKeys.forEach((categoryKey) => {
      const category = clinicalInputs[categoryKey]

      Object.keys(category).forEach((oldKey) => {
        const newKeyWithUnderscores = oldKey.replace(/[.]/g, '_')

        if (newKeyWithUnderscores === oldKey) return

        category[newKeyWithUnderscores] = category[oldKey]
        delete category[oldKey]
      })
    })
  }

  postEvaluationTransform(synapseModel) {
    const {
      clinicalSourceInput: { mode },
    } = synapseModel

    if (mode === 'verboseCriteria') {
      synapseModel.valueSets = synapseModel.clinicalSourceInput.valueSets
      synapseModel.matchedIndications =
        synapseModel.clinicalSourceInput.matchedNodes
    }

    synapseModel.outline.calculatorMatches = synapseModel.clinicalSourceInput.calculatorCache.get(
      'matches'
    )
    synapseModel.outline.indications = synapseModel.clinicalSourceInput.matches
    synapseModel.clinicalInput.patientMeta.now = new Date(
      synapseModel.clinicalSourceInput.patientMeta.fNow
    ).toISOString()
    synapseModel.clinicalInput.patientMeta.encounterStart = new Date(
      synapseModel.clinicalSourceInput.patientMeta.fEncounterStart
    ).toISOString()

    delete synapseModel.clinicalSourceInput
    delete synapseModel.encounterStart

    this.replaceDotsWithUnderscores(synapseModel.clinicalInput)
  }

  transformOperativeStatusCriteria(operativeStatusCriteria) {
    if (operativeStatusCriteria.Content) {
      this.addKeyIdAndParentIdToOperativeStatusCriteria(
        operativeStatusCriteria.Content,
        'id_osc'
      )
    }
    return operativeStatusCriteria
  }

  addKeyIdAndParentIdToOperativeStatusCriteria(
    operativeStatusCriteriaItems,
    parentId
  ) {
    operativeStatusCriteriaItems.forEach((operativeStatusItem, index) => {
      const contentId = (index + 1).toString().padStart(2, 0)
      operativeStatusItem.idKey = `${parentId}_${contentId}`
      operativeStatusItem.parentId = parentId

      if (operativeStatusItem.ContentItems) {
        this.addKeyIdAndParentIdToOperativeStatusCriteria(
          operativeStatusItem.ContentItems,
          operativeStatusItem.idKey
        )
      }
    })
  }
}

module.exports = GuidanceManagerModule
