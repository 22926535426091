'use strict'
const { convertBundleToScdm } = require('./fhirBundle')
const GuidanceManagerModule = require('./guidanceManager')
const guidanceManager = new GuidanceManagerModule()

export function buildModel(body, edition, logger = console) {
  const { body: scdm } = convertBundleToScdm(body, logger)
  const { offset = 0 } = scdm.config
  const { responseType: mode = '' } = scdm.data

  const synapseModel = guidanceManager.evaluateClinicalInput({
    clinicalInput: scdm.data,
    guideline: {},
    requestLogger: logger,
    contentEdition: edition,
    remedyBuild: scdm.config.remedyBuildNumber,
    offset,
    mode,
    coverageMode: null,
  })

  return synapseModel
}
