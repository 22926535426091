'use strict'

class MedicationAdministration {
  constructor(medicationAdministrationData) {
    this.medicationAdministrations = {}
    medicationAdministrationData.forEach((item) => this.parseItem(item))
  }

  static genID() {
    return `${Math.random()
      .toString(36)
      .substring(2)}${Date.now().toString(36)}`
  }

  parseItem(item) {
    const {
      id,
      resourceType,
      status,
      wasNotGiven: r2NotGiven = null,
      notGiven: r3NotGiven = null,
      encounter: { reference: r2EncounterRef = null } = {},
      context: { reference: r3EncounterRef = null } = {},
      effectiveTimeDateTime: r2DateTime,
      effectiveDateTime: r3DateTime,
      effectiveTimePeriod: { start: r2StartTime } = {},
      effectivePeriod: { start: r3StartTime } = {},
      dosage: { quantity: r2Dose, dose: r3Dose } = {},
      reference: r3Reference,
      prescription: { reference: r2Reference = '' } = {},
      medicationCodeableConcept: { coding: codings = [], text = '' } = {},
    } = item

    const clinicalInputKey =
      id && resourceType
        ? `${id}_${resourceType}`
        : MedicationAdministration.genID()
    const { value = null, unit = null } = r2Dose || r3Dose || {}
    const dateTime =
      r2DateTime || r2StartTime || r3DateTime || r3StartTime || null
    const wasNotGiven = r2NotGiven || r3NotGiven
    const orderReference = r2Reference || r3Reference
    const encounterReference = r2EncounterRef || r3EncounterRef

    if (status !== 'entered-in-error') {
      this.medicationAdministrations[clinicalInputKey] = {
        clinicalInputId: id,
        clinicalInputKey,
        orderReference,
        encounterReference,
        text,
        status,
        wasNotGiven,
        codings,
        value,
        unit,
        dateTime,
        sourceMapKey: 'sMedicationAdministrationsMap',
      }
    }
  }
}

module.exports = MedicationAdministration
