<script>
import { mapGetters, mapActions } from 'vuex'
import { errorRedirectMixin } from '@mixins/error-redirect'

const oneHour = 60
function sec(minutes) {
  return minutes * 60
}
function ms(minutes) {
  return minutes * 60000
}

function formatSeconds(sec) {
  const minutes = Math.floor(sec / 60)
  const seconds = Math.floor(sec % 60)
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return `${formattedMinutes}:${formattedSeconds}`
}

export default {
  mixins: [errorRedirectMixin],
  props: {
    message: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    cancelText: { type: String, default: '' },
  },
  data() {
    return {
      isActive: false,
      endingSession: false,
      sessionTimeout: 0,
      inactivityTimeout: 0,
      inactivityInterval: 0,
      countdown: 0,
      countdownMinutes: 0,
      inactivityMinutes: 0,
      sessionMinutes: 0,
      displayInterval: 0,
      displayCountdown: 0,
      sessionStart: 0,
    }
  },
  computed: {
    ...mapGetters('clientConfig', ['clientConfig']),
    countdownText() {
      return `Your session will end in ${Math.floor(this.countdown)} seconds.`
    },
    timeBeforeSessionWarning() {
      return sec(this.sessionMinutes - this.inactivityMinutes)
    },
  },
  created: function () {
    if (!this.clientConfig.isLoaded) {
      this.getClientConfig(this)
      this.enableEventListeners(this)
    }
  },
  destroyed: function () {
    this.disableEventListeners(this)
  },
  methods: {
    ...mapActions('clientConfig', ['retrieveClientConfig']),
    events: function (event) {
      this.setInactivityTimeout(this)
    },
    elapsedSeconds() {
      return (Date.now() - this.sessionStart) / 1000
    },
    sessionDisplayTime() {
      return this.elapsedSeconds() > this.timeBeforeSessionWarning
        ? sec(this.sessionMinutes) - this.elapsedSeconds()
        : this.timeBeforeSessionWarning - this.elapsedSeconds()
    },
    getClientConfig(self) {
      self
        .retrieveClientConfig({ sessionId: self.$route.query.sessionId })
        .then((clientConfig) => {
          self.inactivityMinutes = clientConfig.inactivityTimeoutMinutes || 5
          self.sessionMinutes = clientConfig.sessionTimeoutMinutes || oneHour
          self.countdownMinutes = clientConfig.sessionCountdownTimerMinutes || 2
          self.sessionStart = Date.now()
          self.setSessionTimeout(self)
          self.setInactivityTimeout(self)
        })
    },
    setSessionTimeout(self) {
      self.sessionTimeout = setTimeout(() => {
        self.endingSession = true
        self.showTimeoutWarning(self)
        self.sessionTimeout = setTimeout(() => {
          self.cancelSession()
        }, ms(self.inactivityMinutes))
      }, ms(self.sessionMinutes - self.inactivityMinutes))
    },
    setInactivityTimeout(self) {
      // this will get reset by keyboard/mouse events
      clearTimeout(self.inactivityTimeout)
      clearInterval(self.inactivityInterval)
      self.countdown = self.countdownMinutes

      self.inactivityTimeout = setTimeout(() => {
        self.showTimeoutWarning(self)
      }, ms(self.inactivityMinutes - self.countdown))

      // Display
      clearInterval(self.displayInterval)
      self.displayCountdown = sec(self.inactivityMinutes - self.countdown)
      self.displayInterval = setInterval(() => {
        self.displayCountdown--
        if (self.displayCountdown < 1) {
          clearInterval(self.displayInterval)
        }

        self.$emit(
          'displayTimer',
          self.remainingTime(
            self.elapsedSeconds() > self.timeBeforeSessionWarning,
            Math.floor(self.sessionDisplayTime()),
            Math.floor(self.displayCountdown)
          )
        )
      }, 1000)
    },
    remainingTime(sessionEnding, sessionRemaining, inactivityRemaining) {
      if (!sessionRemaining || sessionRemaining < 1) return ''

      return `${
        sessionEnding ? 'Session Ending' : 'Session Warning'
      } ${formatSeconds(sessionRemaining)} Inactivity: ${formatSeconds(
        inactivityRemaining
      )}`
    },
    showTimeoutWarning(self) {
      clearInterval(self.displayInterval)
      clearInterval(self.inactivityInterval)
      clearTimeout(self.inactivityTimeout)

      self.disableEventListeners(self)
      self.$emit('timeoutWarning')
      self.isActive = true
      self.countdown =
        self.elapsedSeconds() > self.timeBeforeSessionWarning
          ? sec(self.sessionMinutes) - self.elapsedSeconds()
          : sec(self.countdownMinutes)

      self.inactivityInterval = setInterval(() => {
        self.countdown--
        if (self.countdown < 1) {
          self.cancelSession()
        }
      }, 1000)
    },
    cancelSession() {
      this.disableEventListeners(this)
      clearInterval(this.inactivityInterval)
      clearInterval(this.displayInterval)
      this.isActive = false
      this.$router.replace({ path: '/end-session' })
    },
    continueSession() {
      this.setInactivityTimeout(this)
      this.isActive = false
      this.enableEventListeners(this)
    },
    enableEventListeners(self) {
      window.addEventListener('keydown', self.events)
      window.addEventListener('mousedown', self.events)
      window.addEventListener('scroll', self.events)
    },
    disableEventListeners(self) {
      window.removeEventListener('keydown', self.events)
      window.removeEventListener('mousedown', self.events)
      window.removeEventListener('scroll', self.events)
    },
  },
}
</script>

<template>
  <b-modal v-model="isActive" class="card warning-card">
    <div class="dialog-center">
      <div class="flex">
        <div v-if="!endingSession" class="image">
          <div class="card-image left">
            <img
              alt="Time out warning"
              src="@assets/images/svgs/exclamation.svg"
            />
          </div>
        </div>
        <div v-else class="image">
          <div class="card-image left">
            <img alt="Time out warning" src="@assets/images/svgs/stop.svg" />
          </div>
        </div>
        <div class="dialog-message">
          <div class="content">
            <div class="warning-header" data-cy="warning-header">
              {{ message }}
            </div>
            <div class="countdown">
              {{ countdownText }}
            </div>
            <div class="warning-footer red">
              Ending your session will lose any data entered
            </div>
          </div>
        </div>
      </div>
      <div v-if="!endingSession" class="buttons">
        <br />
        <button
          id="close-button"
          data-cy="cancel-session"
          class="submit button is-warning"
          @click="cancelSession"
        >
          {{ cancelText }}
        </button>
        <div class="space"></div>
        <button
          id="close-button"
          data-cy="continue-session"
          class="submit button is-primary"
          @click="continueSession"
        >
          {{ confirmText }}
        </button>
      </div>
      <div v-else class="buttons">
        <button
          id="ok-button"
          data-cy="ok-button"
          class="submit button is-primary right"
          @click="continueSession"
          >OK</button
        >
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
.dialog-center {
  top: 50%;
  left: 50%;
  justify-content: center;
  width: 50%;
  padding: 2%;
  margin: 25%;
  border: solid 2px gray;
  transform: translated(-50%, -50%);
}

.flex {
  display: flex;
}

.div-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  margin: auto;
}

.dialog-message {
  margin: 10px 0 5px 20px;
  text-align: center;
}

.warning-header {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.countdown {
  margin: 10px 0 20px 0;
  font-size: 16px;
}

.warning-footer {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.buttons {
  display: block;
  margin: 5px 0 0 70px;
  clear: both;
}

.space {
  margin: 0 10px 0 10px;
}

.right {
  margin-right: 0;
  margin-left: auto;
}

.red {
  color: red;
}
</style>
