import { NoGuidelinesApplyConstants } from '@utils/constants'
const CompositePrefixMap = {
  ISC: '001',
  BHG: '002',
  BH: '003',
  RFC: '004',
  GRG: '005',
  AC: '006',
  PIP: '007',
  CCG: '008',
  HC: '009',
  SR: '010',
  SP_PTED: '011',
  MCM: '012',
  TC: '013',
  TCSP: '014',
  MCR: '015',
}

export function buildCompositeHsim(product, hsim) {
  const prefix = CompositePrefixMap[product?.toUpperCase()]
  if (hsim === NoGuidelinesApplyConstants.Hsim) return hsim

  if (prefix == null) {
    throw new Error('Unexpected product code')
  }

  return `${prefix}${hsim}`
}
