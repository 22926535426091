import axios from 'axios'

export const state = {
  clientConfig: {},
  clientConfigError: null,
}

export const getters = {
  clientConfig: (s) => s.clientConfig,
  clientConfigError: (s) => s.clientConfigError,
  clientConfigErrorCode: (s) => s.clientConfigError?.response?.data?.errorCode,
}

export const mutations = {
  CLIENT_CONFIG(s, newValue) {
    s.clientConfig = newValue
  },
  CLIENT_CONFIG_ERROR(s, newValue) {
    s.clientConfigError = newValue
  },
}

export const actions = {
  retrieveClientConfig({ commit }, { sessionId }) {
    return axios
      .get(`/api/Dtr/ClientConfig?session=${sessionId}`)
      .then((response) => {
        const clientConfig = response.data
        clientConfig.isLoaded = true

        commit('CLIENT_CONFIG', clientConfig)
        commit('CLIENT_CONFIG_ERROR', null)
        return clientConfig
      })
      .catch((error) => {
        commit('CLIENT_CONFIG_ERROR', error)
        return error
      })
  },
}
