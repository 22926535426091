export const state = {
  xsrfToken: null,
}

export const getters = {
  xsrfToken: (s) => s.xsrfToken,
}

export const mutations = {
  UPDATE_XSRF_TOKEN(s, newValue) {
    s.xsrfToken = newValue
  },
}

export const actions = {
  storeXsrfToken({ commit }, { xsrfToken }) {
    commit('UPDATE_XSRF_TOKEN', xsrfToken)
  },
}
