const ErrorMessages = {
  AttachmentPanel: {
    FileTooLarge:
      'File was too large. Please make sure the selected file has a size smaller than 25 MB.',
    FileZeroSize:
      'File size was zero. Please make sure the selected file has a size greater than 0 byte.',
    FilenameTooLong:
      'Filename was too long. Please make sure the selected file has a name less than or equal to 100 characters.',
    InvalidFileExtension:
      'Selected file was invalid. Please review the supported file types listed below and try again.',
    UploadError: 'Server Error: Unable to upload file. Please try again.',
  },
  Backend: {
    EmptySession:
      'Something went wrong. We will have things fixed in no time. Please try one of the following steps to get you back on track.',
    SearchFailed:
      'No items match your search. Please enter a new search term or code and try again',
    NoGuidelineFound:
      'We didn’t find any guidelines that matched your request. Please check that the provided diagnosis or procedure codes are correct before trying your request again...',
    UnexpectedError:
      'It looks like we ran into an issue. Please close this page and try again.',
  },
}

export default ErrorMessages
