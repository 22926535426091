export const state = {
  shouldExpandClinicalData: false,
}

export const getters = {
  shouldExpandClinicalData: (s) => s.shouldExpandClinicalData,
}

export const mutations = {
  SET_SHOULD_EXPAND_CLINICAL_DATA(s, newValue) {
    s.shouldExpandClinicalData = newValue
  },
}

export const actions = {
  setShouldExpandClinicalData({ commit }, value) {
    commit('SET_SHOULD_EXPAND_CLINICAL_DATA', value)
  },
}
