export const state = {
  authSummary: {},
  authSummaryError: null,
}

export const getters = {
  pasAuthSummary: (s) => s.authSummary,
  pasAuthSummaryError: (s) => s.authSummaryError,
}

export const mutations = {
  AUTH_SUMMARY(s, newValue) {
    s.authSummary = newValue
  },
  AUTH_SUMMARY_ERROR(s, newValue) {
    s.authSummaryError = newValue?.response?.data || null
  },
}

export const actions = {
  getAuthSummary({ commit }) {
    setTimeout(() => {
      const status = ['Pending', 'Approved', 'Denied']
      const pasAuthSummary = {
        status: getRandomStringFromArray(status),
        transactionDate: '2022-03-09 15:30:45',
        memberId: '720093350',
        insurer: 'Payer D',
        authId: '005459811',
        firstName: 'James',
        lastName: 'Williams',
      }

      commit('AUTH_SUMMARY', pasAuthSummary)
      commit('AUTH_SUMMARY_ERROR', null)
    }, 2000)
  },
}

export function getRandomStringFromArray(array) {
  const randomIndex = Math.floor(Math.random() * array.length)
  return array[randomIndex]
}
