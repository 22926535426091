<template>
  <transition name="fade">
    <div class="modal">
      <div class="mask" @click="$emit('close')" />
      <div>
        <button
          id="close-popup"
          class="submit button is-primary"
          @click="$emit('close')"
        >
          Close
        </button>
      </div>
      <iframe
        id="privacy-policy"
        title="Privacy Policy"
        src="https://www.mcg.com/privacy-policy/"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    file: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 3.25% 7.5% 7.5% 7.5%;
  opacity: 1;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: ' ';
  }

  > div {
    position: relative;
    z-index: 1;
    display: block;
    padding: 7.5px;
    background: #fff;
  }

  iframe {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    background: white;
    border: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
