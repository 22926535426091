import axios from 'axios'

export const state = {
  questionnaire: {},
  libraryReferenceId: '',
  questionnaireError: null,
}

export const getters = {
  questionnaire: (s) => s.questionnaire,
  questionnaireError: (s) => s.questionnaireError,
  libraryReferenceId: (s) => s.libraryReferenceId,
  questionnaireErrorCode: (s) =>
    s.questionnaireError?.response?.data?.errorCode,
}

export const mutations = {
  QUESTIONNAIRE(s, newValue) {
    s.questionnaire = newValue
  },
  LIBRARY_REFERENCE_ID(s, newValue) {
    s.libraryReferenceId = newValue
  },
  QUESTIONNAIRE_ERROR(s, newValue) {
    s.questionnaireError = newValue
  },
}

export const actions = {
  retrieveQuestionnaire({ commit }, { sessionId, libraryId }) {
    return axios
      .get(`/api/Dtr/Questionnaire?questionnaireId=${libraryId}=${sessionId}`)
      .then((response) => {
        const questionnaire = response.data
        const libraryReferenceId = questionnaire.extension
          .slice(
            0,
            questionnaire.extension.findIndex(
              (element) =>
                element.url ===
                'http://hl7.org/fhir/StructureDefinition/cqf-library'
            )
          )
          .forEach((element) => {
            this.libraryReferenceId = element.valueCanonical
          })

        commit('QUESTIONNAIRE', questionnaire)
        commit('QUESTIONNAIRE_ERROR', null)
        commit('LIBRARY_REFERENCE_ID', libraryReferenceId)
        return questionnaire
      })
      .catch((error) => {
        commit('QUESTIONNAIRE_ERROR', error)
        return error
      })
  },
}
