<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from '@layouts/main.vue'

export default {
  page: {
    title: 'PAS Auth Summary',
    meta: [{ name: 'pas', content: 'auth summary' }],
  },
  components: { Layout },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('pasAuthSummary', ['pasAuthSummary']),
    statusClass() {
      const authStatus = this.pasAuthSummary?.status
      return {
        'status-pending': authStatus === 'Pending',
        'status-approved': authStatus === 'Approved',
        'status-denied': authStatus === 'Denied',
      }
    },
    sessionId() {
      return this.$route?.query?.sessionId
    },
  },

  watch: {
    pasAuthSummary() {
      this.isLoading = false
    },
  },

  mounted() {
    this.isLoading = true
    this.getAuthSummary()
  },

  methods: {
    ...mapActions('pasAuthSummary', ['getAuthSummary']),
    exitApp() {
      if (navigator.app) {
        navigator.app.exitApp() // For Cordova/PhoneGap app
      } else if (navigator.device) {
        navigator.device.exitApp() // For Cordova/PhoneGap app
      } else {
        window.close() // For browser-based app
      }
    },
  },
}
</script>

<template>
  <Layout>
    <div class="container">
      <div class="mcg-logo-div">
        <img src="../../assets/images/logo.svg" alt="Logo" class="mcg-logo" />
      </div>

      <div class="line-separator"> </div>

      <div class="auth-summary-div">
        <div class="auth-summary-header">
          <p data-cy="auth-summary-header"> Prior Authorization Response</p>
        </div>
        <div class="panel-block">
          <div class="container">
            <div class="columns" style="padding: 20px">
              <div class="column">
                <div class="content">
                  <p>
                    <strong>Message: </strong>
                    Your request has been received. Check status via the
                    Dashboard. Standard turn-around times for authorization
                    reviews apply. Contact the Health Plan with questions.
                  </p>
                </div>
              </div>
              <div class="column">
                <div class="content">
                  <p data-cy="status">
                    <strong>Status: </strong>
                    <span :class="statusClass">
                      {{ pasAuthSummary.status }}
                    </span>
                  </p>

                  <p data-cy="auth-id">
                    <strong>Auth ID: </strong>
                    {{ pasAuthSummary.authId }}
                  </p>
                  <p data-cy="transaction-date">
                    <strong>Transaction Date: </strong>
                    {{ pasAuthSummary.transactionDate }}
                  </p>
                </div>
              </div>
              <div class="column">
                <div class="content">
                  <p data-cy="member-id">
                    <strong>Member ID: </strong>
                    {{ pasAuthSummary.memberId }}
                  </p>
                  <p data-cy="insurer">
                    <strong>Insurer: </strong>
                    {{ pasAuthSummary.insurer }}
                  </p>
                  <p data-cy="first-name">
                    <strong>First Name: </strong>
                    {{ pasAuthSummary.firstName }}
                  </p>
                  <p data-cy="last-name">
                    <strong>Last Name: </strong>
                    {{ pasAuthSummary.lastName }}
                  </p>
                  <p data-cy="session-id-for-debug" style="display: none">
                    {{ sessionId }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exit-div">
        <b-button
          id="btnExitApp"
          class="submit button is-primary"
          data-cy="exit-button"
          @click="exitApp"
        >
          <strong> Exit </strong>
        </b-button>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="overlay"
      tabindex="-1"
      data-cy="is-loading-div"
    >
      <div class="loader"></div>
    </div>
  </Layout>
</template>

<style>
.exit-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 50px;
}

.status-pending {
  height: 30px;
  padding: 5px 10px;
  color: #ebab27bf;
  background-color: #ebab2726;
}

.status-approved {
  height: 30px;
  padding: 5px 10px;
  color: #00a651bf;
  background-color: #00a65126;
}

.status-denied {
  height: 30px;
  padding: 5px 10px;
  color: #e60000bf;
  background-color: #e6000026;
}

.line-separator {
  height: 2px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #025893;
}

.auth-summary-div {
  margin-top: 50px;
  border: 1px solid #005899;
  border-radius: 4px;
}

.auth-summary-header {
  padding: 12px 32px;
  font-weight: bold;
  color: white;
  background-color: #005899;
}

.mcg-logo {
  width: 130px;
  padding-left: 5px;
  margin-top: 50px;
}

.mcg-logo-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader {
  width: 64px;
  height: 64px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
