<template>
  <footer slot="footer">
    <div class="footer-content">
      <Modal v-if="showModal" @close="showModal = false" />
      <img alt="MCG DTR Service" src="@assets/images/mcg-logo.png" />
      <div
        >Copyright &copy; {{ new Date().getFullYear() }} | MCG Health, LLC |
        800-598-2292 |
        <a id="privacy-link" href="#" @click="showPrivacyPolicy">
          Privacy Policy
        </a>
        <div class="time">{{ timer }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import Modal from '@/src/components/privacy-policy.vue'

export default {
  name: 'Footer',
  components: {
    Modal,
  },
  props: {
    timer: { type: String, default: '' },
    timeoutActive: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    timeoutActive: function (newVal) {
      if (newVal) {
        this.showModal = false
      }
    },
  },
  methods: {
    showPrivacyPolicy() {
      this.showModal = true
    },
  },
}
</script>

<style lang="scss">
footer {
  flex: 0 0 0;
  padding: 5px 10px;
  background-color: rgb(245, 245, 245);
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;

    img {
      display: block;
      max-width: 200px;
      height: 45px;
      margin-right: 20px;
      cursor: pointer;
    }

    div {
      display: inline-block;
    }

    .time {
      position: relative;
      left: 350px;
    }
  }
}
</style>
