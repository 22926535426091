import ErrorMessages from '@src/errorMessages'
import { postErrorToWindow } from '@src/postErrorToWindow'

export const errorRedirectMixin = {
  methods: {
    validateError(errorCode, problemDetails) {
      if (this.$route.query && errorCode) {
        postErrorToWindow(
          problemDetails?.Detail,
          this.$route.query.refererUrl,
          this.$route.query.notificationMode
        )
      }
      let errorDetail
      let handledException = false
      if (!problemDetails?.Type) {
        errorDetail = ErrorMessages.Backend.EmptySession
      } else {
        errorDetail = problemDetails?.Detail
        handledException = true
      }
      if (errorCode === 'InvalidSession') {
        this.$router.replace({
          path: '/error',
          query: {
            code: errorCode,
            sessionId: this.$route.query.sessionId,
            detail: errorDetail,
            handled: handledException,
          },
        })
      }
    },
  },
}
