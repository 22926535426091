export const McgFhirExtensions = {
  DiagnosticReportExtensionUrl:
    'https://www.mcg.com/fhir/diagnostic-report-ext',
  DiagnosticReportResultSystemUrl:
    'https://www.mcg.com/fhir/diagnostic-report-ext/result-system',
}

export const Xsrf = {
  StorageKeyPrefix: 'XSRF-TOKEN',
  ValidationHeader: 'X-XSRF-TOKEN',
}

export const MAX_ROWS = 100

export const NoGuidelinesApplyConstants = {
  Hsim: 'NoGuidelineHsimId',
}

export const notCoveredIndication = 'NOT COVERED for Any of the following'

export const coveredIndication = '1 or more of the following'

export const CustomGuidelineDisclaimer =
  'Disclaimer: This guideline may contain custom content that has been modified from the MCG care guideline and has not been reviewed or approved by MCG Health, LLC.'

export const CopyRightText =
  '\nThis document includes content which is the copyrighted, proprietary information of MCG Health, LLC.{{}} All Rights Reserved.'
